import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import SectionTitle from "./section-title";
import PortfolioCard from "@/components/portfolio-card";
import { PortfolioHomeData, PortfolioData } from "@/data";

const PortfolioHome = () => {
  const { sectionContent } = PortfolioHomeData;

  return (
    <section id="products" className="commonSection porfolio">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={sectionContent} />
          </Col>
        </Row>
        <Row id="Grid">
          <div className="custom">
            <Row>
              {/* Show only the first 3 portfolio items */}
              {PortfolioData.slice(0, 3).map((post, index) => (
                <Col lg={4} md={6} sm={12} key={index}>
                  <PortfolioCard data={post} index={index} />
                </Col>
              ))}
            </Row>
          </div>
        </Row>
        {/* "Load More" button in the center */}
        <Row>
          <Col lg={12} className="text-center">
            <Button
              className="common_btn red_bg"
              href="/products" // Directing to products page
            >
              <span>Load More</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PortfolioHome;