import React from "react";

const PortfolioCard = ({ data, index }) => {
  const { image, title, categories, url } = data;

  return (
    <div className="singlefolio">
      <a href={url} className="portfolio-link">
        <img src={image} alt={title} />
        <div className="folioHover">
          <div className="category-list">
            {categories?.map((cat, i) => (
              <span key={i}>
                {cat}
                {i < categories.length - 1 && ", "}
              </span>
            ))}
          </div>
          <h4>{title}</h4>
        </div>
      </a>
    </div>
  );
};

export default PortfolioCard;
